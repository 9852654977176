import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import axios from "axios";
import React, { useState } from "react";
import CloseSVG from "./CloseSVG";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";

function Contact({ handleContact }) {
  const theme = useTheme();

  const [confirmMsg, setConfirmMsg] = useState(null);

  const [formInput, setFormInput] = useState({
    name: "",
    email: "",
    message: "",
    nameError: "",
    emailError: "",
    msgError: "",
  });
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleFormChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      setFormInput((prevState) => {
        return {
          ...prevState,
          nameError: "",
          emailError: "",
          msgError: "",
        };
      });
      setConfirmMsg("Sent!");
      setTimeout(() => {
        handleContact();
        setConfirmMsg(null);
      }, 4000);
    } else {
      setFormInput((prevState) => {
        return {
          ...prevState,
          nameError: "",
          emailError: "",
          msgError: "",
        };
      });
      setConfirmMsg("Email server down, try later");
      setTimeout(() => {
        handleContact();
        setConfirmMsg(null);
      }, 5000);
    }
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let valid = true;

    setFormInput((prevState) => {
      return { ...prevState, nameError: "", emailError: "", msgError: "" };
    });

    if (formInput.name.length < 2) {
      setFormInput((prevState) => {
        return { ...prevState, nameError: "Please enter your name" };
      });
      valid = false;
    }
    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    if (!validateEmail(formInput.email)) {
      setFormInput((prevState) => {
        return { ...prevState, emailError: "Enter Valid Email" };
      });
      valid = false;
    }

    if (formInput.message.length < 2) {
      setFormInput((prevState) => {
        return {
          ...prevState,
          msgError: "Dont you have something to say?",
        };
      });
      valid = false;
    }
    console.log(formInput);
    if (valid) {
      setServerState({
        ...serverState,
        submitting: true,
      });

      axios({
        method: "POST",
        url: "https://formspree.io/f/mnqoybal",
        data: formInput,
      })
        .then((r) => {
          handleServerResponse(true, "Thanks!");
        })
        .catch((r) => {
          handleServerResponse(false, r.response.data.error);
        });
    }
  };
  //   const useStyles = makeStyles((theme) => ({
  //     root: {
  //       height: "auto",
  //       width: "50vw",
  //       backgroundColor: "white",
  //       margin: "auto 25%",
  //       padding: "2em",
  //       position: "absolute",

  //       [theme.breakpoints.only("xs")]: {
  //         width: "90vw",
  //         padding: "1em",
  //         margin: "auto 5%",
  //         left: "0",
  //       },
  //       [theme.breakpoints.between("sm", "md")]: {
  //         width: "70vw",
  //         padding: "1em",
  //         margin: "auto 15%",
  //         left: "0",
  //       },
  //       "& label": {
  //         color: "#4D4D4D",
  //       },
  //       "& .MuiInput-underline:after": {
  //         borderBottomColor: "black",
  //       },
  //       "& .MuiOutlinedInput-root": {
  //         "& fieldset": {
  //           borderColor: "black",
  //         },
  //         "&:hover fieldset": {
  //           borderColor: "black",
  //           color: "black",
  //         },
  //         "&.Mui-focused fieldset": {
  //           borderColor: "black",
  //         },
  //       },
  //     },
  //     contactText: {
  //       width: "80%",
  //       margin: "1em 10%",
  //     },
  //     infoText: {
  //       width: "90%",
  //       maxWidth: "50ch",
  //       marginLeft: "10%",
  //     },
  //     contactClose: {
  //       position: "absolute",
  //       display: "flex",
  //       right: "15px",
  //       top: "15px",
  //       width: "3.5em",
  //       zIndex: "9999999",
  //       "&:hover": {
  //         cursor: "pointer",
  //       },
  //       [theme.breakpoints.only("xs")]: {
  //         width: "2em",
  //         right: "10px",
  //         top: "10px",
  //       },
  //       [theme.breakpoints.between("sm", "md")]: {
  //         width: "3em",
  //         right: "10px",
  //         top: "10px",
  //       },
  //     },
  //     fontColor: {
  //       color: "#4AC6B1 ",
  //     },
  //   }));
  const classes = "";
  return (
    <Paper
      sx={{
        height: "auto",
        width: "50vw",
        backgroundColor: "white",
        margin: "auto 25%",
        padding: "2em",
        position: "absolute",

        [theme.breakpoints.only("xs")]: {
          width: "90vw",
          padding: "1em",
          margin: "auto 5%",
          left: "0",
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: "70vw",
          padding: "1em",
          margin: "auto 15%",
          left: "0",
        },
        "& label": {
          color: "#4D4D4D",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "black",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "black",
          },
          "&:hover fieldset": {
            borderColor: "black",
            color: "black",
          },
          "&.Mui-focused fieldset": {
            borderColor: "black",
          },
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          right: "15px",
          top: "15px",
          width: "3.5em",
          zIndex: "9999999",
          "&:hover": {
            cursor: "pointer",
          },
          [theme.breakpoints.only("xs")]: {
            width: "2em",
            right: "10px",
            top: "10px",
          },
          [theme.breakpoints.between("sm", "md")]: {
            width: "3em",
            right: "10px",
            top: "10px",
          },
        }}
        onClick={() => handleContact()}
      >
        <CloseSVG />
      </Box>
      <Grid container align="start" direction="column" justify="center">
        <Grid align="center">
          <Typography
            variant="h3"
            color="secondary"
            style={{ marginBottom: "30px" }}
          >
            Contact
          </Typography>
        </Grid>
        <form action="https://formspree.io/f/mnqoybal" method="POST">
          <Grid align="center">
            <TextField
              sx={{ width: "90%", maxWidth: "50ch", marginLeft: "10%" }}
              error={formInput.nameError.length > 2}
              helperText={` ${formInput.nameError}`}
              required
              variant="standard"
              name="name"
              id="full-name"
              value={formInput.name}
              onChange={handleFormChange}
              label="Full Name"
              placeholder="Full Name"
            />
          </Grid>
          <Grid align="center">
            <TextField
              sx={{ width: "90%", maxWidth: "50ch", marginLeft: "10%" }}
              required
              error={formInput.emailError.length > 2}
              helperText={` ${formInput.emailError}`}
              variant="standard"
              name="email"
              value={formInput.email}
              id="email"
              label="Email"
              onChange={handleFormChange}
              placeholder="Email"
            />
          </Grid>
          <Grid align="center">
            <TextField
              error={formInput.msgError.length > 2}
              helperText={` ${formInput.msgError}`}
              required
              sx={{ width: "80%", margin: "1em 10%" }}
              id="message"
              label="Contact Me"
              name="message"
              onChange={handleFormChange}
              value={formInput.message}
              multiline
              rows={5}
              placeholder="Contact Me"
              variant="outlined"
              color="primary"
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
          <Grid align="center">
            {confirmMsg ? (
              <Typography variant="h4" className={classes.fontColor}>
                {confirmMsg}
              </Typography>
            ) : serverState.submitting ? (
              <CircularProgress />
            ) : (
              <Button
                style={{ width: "100px" }}
                variant="contained"
                color="primary"
                onClick={handleOnSubmit}
              >
                Send
              </Button>
            )}
          </Grid>
        </form>
      </Grid>
    </Paper>
  );
}

export default Contact;
