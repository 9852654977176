import { Box, Paper } from "@mui/material";
import React from "react";
import AboutSection from "./AboutSection";
import MyProjects from "./MyProjects";
import Skills from "./Skills";

function Works() {
  return (
    <section id="about">
      <div>
        <Box
          sx={{
            backgroundColor: "#DFDFDF",
            display: "table",
            width: "100%",
            overflow: "auto",
            paddingBottom: "40px",
          }}
        >
          <Paper
            sx={{
              width: "94%",
              margin: "-2% 3%",
              position: "relative",
              height: "auto",
              padding: "5em",
            }}
            elevation={3}
          >
            <AboutSection />
            <Skills />
            <MyProjects />
          </Paper>
        </Box>
      </div>
    </section>
  );
}

export default Works;
