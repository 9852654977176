import React from "react";
import SkillTree from "./SkillTree";

function FrontendSkillTree({ frontendEnter }) {
  return (
    <>
      <SkillTree percent="80" skill="React" enter={frontendEnter} />
      <SkillTree percent="70" skill="HTML/CSS" enter={frontendEnter} />
      <SkillTree percent="80" skill="Javascript" enter={frontendEnter} />
      <SkillTree percent="70" skill="Material UI" enter={frontendEnter} />
    </>
  );
}

export default FrontendSkillTree;
