import { AppBar, Grid, styled, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link as Scroll } from "react-scroll";
import Pdf from "../documents/PDFResume.pdf";
import logo5 from "../svg/logo5.svg";

const NavText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  display: "inline-block",
  padding: "5px 20px",
  position: "relative",
  cursor: "pointer",
  "&:hover": {
    color: "white",
  },
  "&:after": {
    background: "none repeat scroll 0 0 transparent",
    bottom: "0px",
    content: "''",
    display: "block",
    height: "3px",
    left: "50%",
    position: "absolute",
    backgroundColor: "#4ac6b1",
    transition: "width 0.3s ease 0s, left 0.3s ease 0s",
    width: "0px",
  },
  "&:hover:after": {
    width: "70%",
    left: "15%",
  },
}));

function Navbar(props) {
  const openResume = () => {
    window.open(Pdf);
  };

  return (
    <>
      <AppBar position="absolute" color="transparent" elevation={0}>
        <Toolbar>
          <Grid xs={3} justifyContent="center" container item>
            <object
              type="image/svg+xml"
              data={logo5}
              style={{ height: "50px", marginTop: "5px" }}
            >
              svg-animation
            </object>
          </Grid>

          <Grid xs={6} container item justifyContent="center">
            <Scroll to="about" smooth={true} duration={1000}>
              <NavText variant="h6" className="middle" color="primary">
                About
              </NavText>
            </Scroll>
            <Scroll to="projects" smooth={true} duration={1000}>
              <NavText variant="h6" className="middle" color="primary">
                Projects
              </NavText>
            </Scroll>
            <div onClick={props.handleContact}>
              <NavText variant="h6" className="middle" color="primary">
                Contact
              </NavText>
            </div>
            <div onClick={() => openResume()}>
              <NavText variant="h6" className="middle" color="primary">
                Resume
              </NavText>
            </div>
          </Grid>
          <Grid xs={3} container item justifyContent="center"></Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;
