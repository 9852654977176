import React from "react";
import { ReactSVG } from "react-svg";
import close13 from "../svg/close13.svg";

function CloseSVG() {
  return (
    <div>
      {/* <img style={{ height: "50px" }} src={close13} alt="f" /> */}
      <ReactSVG
        style={{ cursor: "pointer", height: "50px", width: "50px" }}
        src={close13}
      ></ReactSVG>
      {/* <object style={{ height: "50px" }} data={close13} type="image/svg+xml">
        r
      </object> */}
    </div>
  );
}

export default CloseSVG;
