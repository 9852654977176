import { useState } from "react";
import { animated, useTrail } from "react-spring";
import { useGesture } from "@use-gesture/react";
import django from "../img/django1.png";
import javascript from "../img/javascript.png";
import mui from "../img/mui.png";
import python from "../img/python1.png";
import react from "../img/react2.png";
import redux from "../img/redux2.png";
import spring from "../img/spring2.png";
import useMeasure from "react-use-measure";

import "../css/pull.css";

function PullRelease({
  dropZonesRef,
  setHovering,
  hovering,
  reveal,
  setReveal,
  setDragged,
  top,
  left,
}) {
  const [drag, setDrag] = useState(false);

  const stacks = [javascript, django, react, redux, mui, python, spring];

  const trans = (x, y) =>
    `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`;

  const [trail, set] = useTrail(5, (i) => ({
    xy: [0, 0],
  }));

  let moved = [0, 0];
  let moving = [0, 0];

  const bind = useGesture({
    onDragStart: () => setDrag(true),
    onDrag: ({ offset: [x, y], movement: [mx, my], xy }) => {
      setDragged(false);
      moved = [mx + moving[0], my + moving[1]];
      set.start({ xy: moved });
    },
    onDragEnd: ({ xy: [x, y], offset, first, movement: [mx, my] }) => {
      moving = moved;
    },
  });
  // Bind it to a component

  // const useStyles = makeStyles({
  //   divy: {
  //     backgroundSize: "cover",
  //     height: "70px",
  //     width: "70px",
  //     zIndex: "100",
  //     position: "absolute",
  //     marginTop: "3rem",
  //     willChange: "transform",
  //     touchAction: "none",
  //     overflow: "hidden",
  //     marginLeft: "20px",
  //     cursor: "grab",
  //   },
  //   divyy: {
  //     height: "100%",
  //     backgroundSize: "cover",
  //     margin: "0vw 0",
  //   },
  // });

  return (
    <>
      {trail.map((animation, i) => (
        <animated.div
          {...bind()}
          key={i}
          className="bob"
          style={{
            transform: animation.xy.to(trans),
            zIndex: 10 - i,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${stacks[i]})`,
              height: "100%",
              backgroundSize: "cover",
            }}
          />
        </animated.div>
      ))}
    </>
  );
}

export default PullRelease;
