import React from "react";
import SkillTree from "./SkillTree";

function BackendSkillsTree({ backendEnter }) {
  return (
    <>
      <SkillTree percent="70" skill="Python" enter={backendEnter} />
      <SkillTree percent="70" skill="Django" enter={backendEnter} />
      <SkillTree percent="30" skill="SQL" enter={backendEnter} />
      <SkillTree
        percent="70"
        skill="Django Rest Framework"
        enter={backendEnter}
      />
    </>
  );
}

export default BackendSkillsTree;
