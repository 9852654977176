import { Backdrop, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Contact from "./components/Contact";
import MyIntro from "./components/MyIntro";
import Navbar from "./components/Navbar";
import Works from "./components/Works";
import bgcode2 from "./img/bgcode2.png";

function Home() {
  const [openContact, setOpenContact] = useState(false);

  const handleContact = () => {
    setOpenContact(!openContact);
  };

  return (
    <>
      <Navbar handleContact={handleContact} />

      <section>
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            backgroundImage: `url(${bgcode2})`,
            backgroundSize: "cover",
          }}
        >
          <MyIntro />
        </Box>
        <Works />
        <Backdrop sx={{ zIndex: "1000", color: "#fff" }} open={openContact}>
          <Contact handleContact={handleContact} />
        </Backdrop>
      </section>
    </>
  );
}

export default Home;
