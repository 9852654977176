import { Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";

import { Link as Scroll } from "react-scroll";
import {
  animated,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from "react-spring";

function MyIntro() {
  const [intro, setIntro] = useState(true);
  const theme = useTheme();

  const calc = (x, y) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    1.02,
  ];

  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

  const [tilt, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  const fadeRef = useSpringRef();
  const colorRef = useSpringRef();
  const trans1Ref = useSpringRef();
  const trans2Ref = useSpringRef();
  const trans3Ref = useSpringRef();

  const color = useSpring({
    ref: colorRef,
    from: { color: `${theme.palette.primary.main}` },
    to: { color: `${theme.palette.white.primary}` },
  });

  const fadeIn = useSpring({
    ref: fadeRef,
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 600 },
  });

  const trans1 = useTransition(intro, {
    from: { transform: "translate3d(-1000%,0,0)" },
    enter: { transform: "translate3d(0,0px,0)" },
    leave: { transform: "translate3d(0,0px,0)" },
    ref: trans1Ref,
    config: { mass: 1, tension: 100, friction: 20 },
  });

  const trans2 = useTransition(intro, {
    from: { transform: "translate3d(-1000%,0,0)" },
    enter: { transform: "translate3d(0,0,0)" },
    leave: { transform: "translate3d(0,0px,0)" },
    delay: 1000,

    ref: trans2Ref,
    config: { mass: 1, tension: 100, friction: 20 },
  });

  const trans3 = useTransition(intro, {
    from: { transform: "translate3d(0,-1000px,0)" },
    enter: { transform: "translate3d(0,0px,0)" },
    leave: { transform: "translate3d(0,0px,0)" },
    ref: trans3Ref,
    delay: 500,
    config: { mass: 1, tension: 100, friction: 20 },
  });

  useChain([trans1Ref, trans2Ref, colorRef, trans3Ref, fadeRef]);

  return (
    <animated.div
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{
        transform: tilt.xys.to(trans),
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        {trans1((style) => (
          <animated.div style={style}>
            <Typography color="textSecondary" variant="h3">
              Hi,{" "}
            </Typography>
          </animated.div>
        ))}
        {trans2((style) => (
          <animated.div style={style}>
            <Typography color="textSecondary" variant="h3">
              &nbsp;I am{" "}
              <animated.span style={color}>Lukas Puckerin</animated.span>
            </Typography>
          </animated.div>
        ))}
      </Grid>

      {trans3((style) => (
        <animated.div style={style}>
          <Typography align="center" color="textSecondary" variant="h5">
            I am a full stack web developer
          </Typography>
        </animated.div>
      ))}
      <Grid justifyContent="center" container style={{ marginTop: "30px" }}>
        <animated.div style={fadeIn}>
          <Button variant="outlined" color="primary">
            <Scroll to="about" smooth={true} duration={1000}>
              About Me
            </Scroll>
          </Button>
        </animated.div>
      </Grid>
    </animated.div>
  );
}

export default MyIntro;
