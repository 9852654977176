import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Waypoint } from "react-waypoint";
import { animated, useSpring } from "react-spring";
import pteal from "../img/pteal.png";

function AboutSection() {
  const [aboutEnter, setAboutEnter] = useState(false);

  const animateAboutEnter = useSpring({
    opacity: aboutEnter ? 1 : 0,
    transform: aboutEnter ? "translate3d(0,0,0)" : "translate3d(50%,0,0)",
  });

  return (
    <Grid container spacing={4} direction="column">
      <Grid
        container
        item
        justifyContent="center"
        style={{ marginBottom: "70px" }}
      >
        <Typography color="fontSecondary" variant="h3">
          About Me
        </Typography>
      </Grid>
      <Grid
        spacing={4}
        container
        alignItems="center"
        style={{ marginBottom: "50px" }}
      >
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <Typography
            color="textSecondary"
            variant="body1"
            sx={{ maxWidth: "45ch" }}
          >
            I am a full stack web developer from Burlington, Ontario Canada. I
            develop dynamic frontend applications using html/css/js and SPA's
            using React. I also develop backend technologies using Django and
            Django Rest Framework.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Waypoint bottomOffset="40%" onEnter={() => setAboutEnter(true)} />
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <animated.div style={animateAboutEnter}>
              <img src={pteal} alt="Programmer Pic" />
            </animated.div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AboutSection;
