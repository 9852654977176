import { Grid, Typography } from "@mui/material";
import React from "react";
import SwiperPar from "./SwiperPar";

function MyProjects() {
  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ marginBottom: "70px" }}
        >
          <Typography
            id="projects"
            color="fontSecondary"
            variant="h3"
            style={{ marginTop: "100px" }}
          >
            Work and Projects
          </Typography>
        </Grid>
        <Grid item>
          <SwiperPar />
        </Grid>
      </Grid>
    </>
  );
}

export default MyProjects;
