import React, { useRef, useState } from "react";
// Import Swiper React components
import { Backdrop, Button, Grid, Link, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "../css/swiper1.css";
import JMRESS1 from "../img/JMRESS1.jpg";
import ledrewsm from "../img/ledrewsm.jpg";
import project4 from "../img/Project4.png";
import swifthome1 from "../img/swifthome1.png";
import swifthome2 from "../img/swifthome2.png";

// import Swiper core and required modules
import { styled } from "@mui/system";
import useMeasure from "react-use-measure";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import PullRelease from "./PullRelease";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const SwiperImg = styled("img")(({ theme }) => ({
  height: "200px",
  objectFit: "fill",
  borderRadius: "10px",
  boxShadow: "1px 1px 10px 4px rgb(106, 108, 122,0.4)",
}));

function SwiperPar() {
  const [ref, { left, top }] = useMeasure();
  const dropZonesRef = useRef(null);

  const [reveal, setReveal] = useState(true);
  const [dragged, setDragged] = useState(true);

  const [hovering, setHovering] = useState({});
  const handleHovering = (index, bool) => {
    setHovering((hovering) => ({
      ...hovering,
      [index]: bool,
    }));
  };

  const [project4Open, setProject4Open] = useState(false);

  // const [aboutEnter, setAboutEnter] = useState(false);

  return (
    <>
      <div style={{ width: "1000px", height: "300px" }}>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          allowTouchMove={false}
          navigation={true}
          className="mySwiper"
        >
          <SwiperSlide>
            <Grid container sx={{ padding: "10px 100px" }} spacing={10}>
              <Grid item xs={6} sx={{ padding: "30px" }}>
                <Link
                  href="https://help.goswiftgrade.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <SwiperImg src={swifthome1} alt="Swiftgrade help center" />
                </Link>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={6}
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography align="left" color="fontSecondary" variant="h5">
                    SwiftGrade Help Center
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    align="left"
                  >
                    Swiftgrade is a startup in the education software space that
                    I am a contributor on. I created Swiftgrade's Help center
                    using React and Material ui as a library.
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    href="https://help.goswiftgrade.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button variant="contained">
                      <Typography style={{ color: "white" }} variant="body2">
                        Visit Website
                      </Typography>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid container style={{ padding: "20px 100px" }} spacing={10}>
              <Grid item xs={6}>
                <Link
                  href="https://goswiftgrade.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <SwiperImg src={swifthome2} alt="Swiftgrade Home page" />
                </Link>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={6}
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography align="left" color="fontSecondary" variant="h5">
                    SwiftGrade Homepage
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    align="left"
                  >
                    I maintain SwiftGrade's homepage. Created using vanilla
                    html,css, and javascript.
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    href="https://goswiftgrade.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button variant="contained">
                      <Typography style={{ color: "white" }} variant="body2">
                        Visit Website
                      </Typography>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid container style={{ padding: "20px 100px" }} spacing={10}>
              <Grid item xs={6}>
                <Link
                  href="https://jordanmartindalerealestate.com/home"
                  rel="noreferrer"
                  target="_blank"
                >
                  <SwiperImg
                    src={JMRESS1}
                    alt="Jordan Martindale real estate"
                  />
                </Link>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={6}
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography align="left" color="fontSecondary" variant="h5">
                    JM Real Estate
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    align="left"
                  >
                    Website for Jordan Martindale Real Estate. Includes dynamic
                    listings, blog, informational pages, Django CMS, email
                    server and more. Built using Django, DRF, React.
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    href="https://jordanmartindalerealestate.com/home"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button variant="contained">
                      <Typography style={{ color: "white" }} variant="body2">
                        Visit Website
                      </Typography>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid container style={{ padding: "20px 100px" }} spacing={10}>
              <Grid item xs={6}>
                <SwiperImg src={project4} alt="Invest" />
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={6}
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography align="left" color="fontSecondary" variant="h5">
                    Investment Advice
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    align="left"
                  >
                    Personal project to provide people tools they need to start
                    investing on their own. The goal is to use charting/graphing
                    libraries and Machine Learning so people can visualize how
                    their money may grow using different risk strategies.
                    Currently under development.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => setProject4Open(!project4Open)}
                  >
                    <Typography style={{ color: "white" }} variant="body2">
                      Visit Website
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid
              container
              style={{ padding: "20px 100px" }}
              spacing={10}
              justifyContent="center"
              alignItems="center"
              ref={ref}
            >
              <Grid item xs={6}>
                <div
                  style={{
                    height: "100px",
                    width: "100px",
                    margin: "0 auto",
                    zIndex: "999999",
                  }}
                >
                  <PullRelease
                    top={top}
                    left={left}
                    hovering={hovering}
                    setHovering={handleHovering}
                    setDragged={setDragged}
                    dropZonesRef={dropZonesRef}
                    reveal={reveal}
                    setReveal={setReveal}
                  />
                </div>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={6}
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography align="left" color="fontSecondary" variant="h5">
                    Other Projects
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    align="left"
                  >
                    Other Projects done for learning purposes include: React
                    Spring(Click and Drag JS Logo for Physics based animation),
                    Real Estate Website(DjangoRestApi, React), Lead
                    Manager(Django, React), Machine Learning Loan
                    Approval(Django Rest Api, SkLearn, TensorFlow)
                  </Typography>
                </Grid>
                <Grid item></Grid>
              </Grid>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid container style={{ padding: "20px 100px" }} spacing={10}>
              <Grid item xs={6}>
                <Link
                  href="https://www.stephenledrew.ca/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <SwiperImg src={ledrewsm} alt="3 Minute Interview" />
                </Link>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={6}
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography align="left" color="fontSecondary" variant="h5">
                    3 Minute Interview
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    align="left"
                  >
                    Website for the host of the 3 Minute interview, a show on
                    youtube. This site was built using Wix, not code. Work was
                    focused on design, and improving SEO and site visibiliy.
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    href="https://www.stephenledrew.ca/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button variant="contained">
                      <Typography style={{ color: "white" }} variant="body2">
                        Visit Website
                      </Typography>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </SwiperSlide>
        </Swiper>
        <Backdrop
          sx={{ zIndex: 1000, color: "#fff" }}
          open={project4Open}
          onClick={() => setProject4Open(false)}
        >
          <Typography
            variant="h4"
            style={{ position: "absolute", top: "50px" }}
          >
            Under development...
          </Typography>
          <img style={{ height: "75vh" }} src={project4} alt="" />
        </Backdrop>
      </div>
      {/* <div style={{ width: "800px" }}>
        <Swiper
        style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Parallax, Pagination, Navigation]}
          className='mySwiper'
        >
          <div
            slot='container-start'
            className='parallax-bg'
            style={{
              backgroundColor: "black",
            }}
            data-swiper-parallax='-23%'
          ></div>
          <SwiperSlide className='slide1'>
            <div className='title' data-swiper-parallax='-300'>
              SwiftGrade Help Center
            </div>
            <div className='subtitle' data-swiper-parallax='-200'>
              React/Material Ui
            </div>
            <div className='text' data-swiper-parallax='-100'>
              <p>
                SwiftGrade is an application that allows teacher to scan tests
                with their phones and instantly get results. I created the
                Swiftgrade Help center using React and Material ui as a library.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='title' data-swiper-parallax='-300'>
              SwiftGrade Home Page
            </div>
            <div className='subtitle' data-swiper-parallax='-200'>
              Html/Css/Js
            </div>
            <div className='text' data-swiper-parallax='-100'>
              <p>
                SwiftGrade's home page was inially developed by a 3rd party. I
                have taken over adding new features to and maintaining the
                homepage.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='title' data-swiper-parallax='-300'>
              Personal Investor Advice
            </div>
            <div className='subtitle' data-swiper-parallax='-200'>
              React/Material Ui
            </div>
            <div className='text' data-swiper-parallax='-100'>
              <p>
                This is a personal project I recently started. The goal of this
                site is to provide people the tools they need to start investing
                on their own. I want to illustrate this to others through tools
                like graph visualizations, walkthroughs, and answers to common
                questions.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: "red" }}>
            <div className='title' data-swiper-parallax='-300'>
              Personal Projects
            </div>
            <div className='subtitle' data-swiper-parallax='-200'>
              React/Django
            </div>
            <div className='text' data-swiper-parallax='-100'>
              <p>
                These are projects built for the purpose of learning, React,
                Django, and Rest Api structure. Other projects include: Real
                Estate Site: Upload, list, and view properties. Lead Manager:
                Create profile, create, update and manage potential leads. Loan
                Machine learning approval: Simple machine learning model to
                approve or deny loan based in input criteria.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div> */}
    </>
  );
}

export default SwiperPar;
