import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import backteal from "../img/backteal.png";
import frontteal from "../img/frontteal.png";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import BackendSkillTree from "./BackendSkillTree";
import FrontendSkillTree from "./FrontendSkillTree";

function Skills() {
  const [frontendEnter, setFrontendEnter] = useState(false);
  const [backendEnter, setBackendEnter] = useState(false);
  const classes = "";
  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ marginBottom: "70px" }}
        >
          <Typography color="fontSecondary" variant="h3">
            skills
          </Typography>
        </Grid>
        <Grid xs={8} item container columnSpacing={5}>
          <Grid item xs={6}>
            <Accordion
              expanded={frontendEnter}
              onClick={() => setFrontendEnter(!frontendEnter)}
              className={classes.accordionRoot}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={frontteal}
                    style={{
                      height: "50px",
                      border: "0px",
                    }}
                    alt=""
                  />
                  <Typography style={{ marginLeft: "30px" }}>
                    Frontend Developer
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"span"}>
                  <FrontendSkillTree frontendEnter={frontendEnter} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={6}>
            <Accordion
              expanded={backendEnter}
              onClick={() => setBackendEnter(!backendEnter)}
              className={classes.accordionRoot}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={backteal}
                    style={{
                      height: "50px",
                      border: "0px",
                    }}
                    alt=""
                  />
                  <Typography style={{ marginLeft: "30px" }}>
                    Backend Developer
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"span"}>
                  <BackendSkillTree backendEnter={backendEnter} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Skills;
