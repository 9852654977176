import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#81f9e3",
      main: "#4ac6b1",
      dark: "#009582",
      contrastText: "#1e1f27",
    },
    secondary: {
      light: "#45464f",
      main: "#1e1f27",
      dark: "#000000",
      contrastText: "#b0b2c3",
    },
    text: {
      primary: "#16171C",
      secondary: "#6a6c7a",
    },
    white: {
      primary: "#fffff",
    },
  },
  // Titillium Web
  typography: {
    fontFamily: "'Poppins', 'sans-serif'",
    h1: {
      fontWeight: "700",
    },
    h2: {
      fontWeight: "700",
    },
    h3: {
      fontWeight: "700",
    },
    h4: {
      fontWeight: "700",
    },
    h5: {
      fontWeight: "700",
    },
    body2: {
      fontFamily: "'Poppins Medium', 'sans-serif'",
    },
  },
});

export default theme;
