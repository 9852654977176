import { Box, Grid, Typography } from "@mui/material";

import React, { useState } from "react";
import { animated, useSpring } from "react-spring";

function SkillTree({ percent, skill, enter, setFrontendEnter }) {
  const [open, toggle] = useState(true);
  const skillAnimate = useSpring({
    width: enter ? `${percent}%` : "0%",
    backgroundColor: "#27debf",
    position: "absolute",
    borderRadius: "2px",
    height: "100%",

    config: {
      mass: randInt(20, 60),
      tension: randInt(230, 300),
      friction: randInt(100, 140),
    },
  });
  function randInt(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  return (
    <>
      <Grid rowSpacing={0.5} sx={{ marginTop: "10px" }} container>
        <Grid item container justifyContent="space-between">
          <Typography component={"span"}>{skill}</Typography>
          <Typography component={"span"} color="textSecondary">
            {percent}%
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "8px",
              backgroundColor: "rgb(39, 222, 191, 40%)",
              cursor: "pointer",
              borderRadius: "2px",
              top: "10%",
            }}
            onClick={() => toggle(!open)}
          >
            <animated.div style={skillAnimate} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default SkillTree;
