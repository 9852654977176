import Home from "./Home";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import "./css/main.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Home />
    </ThemeProvider>
  );
}

export default App;
